<template>
  <b-row>
    <b-col cols="6">
      <b-card title="İşlem Detayı">
        <b-list-group>
          <b-list-group-item>
            <div class="text-primary font-weight-bold">
              İşlem No
            </div>
            <div>
              {{ payment.id }}
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bold">
              İşlem Tutarı
            </div>
            <div>
              {{ payment.amount | toCurrency }} TL
            </div>
            <div class="text-primary font-small-2">
              {{ (payment.instalment === '0')? 'Tek Çekim' : payment.instalment + ' x Taksit' }}
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bold">
              Banka
            </div>
            <div>
              {{ payment.bank }}
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bold">
              Banka İşlem No
            </div>
            <div>
              {{ (payment.bank_reference)? payment.bank_reference : '-' }}
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bold">
              Banka İşlem Sonucu
            </div>
            <div v-if="payment.bank_message">
              <div
                v-for="(bank_message,key) in payment.bank_message"
                :key="key"
              >
                <div
                  v-html="bank_message"
                />
              </div>
            </div>
            <div v-else>
              -
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bold">
              Ip No
            </div>
            <div>
              {{ payment.customer_ip }}
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bold">
              Banka Sipariş No
            </div>
            <div>
              {{ (payment.order_id)? payment.order_id : '-' }}
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bold">
              İşlem Sonucu
            </div>
            <div
              v-if="payment.result"
              class="text-success"
            >
              Başarılı
            </div>
            <div
              v-else
              class="text-danger"
            >
              Başarısız
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bold">
              İşlem Tarihi
            </div>
            <div v-if="payment.modified">
              {{ moment(payment.modified).format('DD.MM.YYYY HH:mm:ss') }}
            </div>
            <div v-else>
              -
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bold">
              İşlemi Gerçekleştiren
            </div>
            <div>
              {{ payment.username }}
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </b-col>
    <b-col cols="6">
      <b-card title="Müşteri Bilgisi">
        <b-list-group>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Firma Ünvanı
            </div>
            <div>{{ customer.company }}</div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Vergi No / Vergi Dairesi
            </div>
            <div>{{ customer.tax_number }} / {{ customer.tax_office }}</div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Telefon
            </div>
            <div>{{ customer.phone }}</div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Adres
            </div>
            <div>{{ customer.address }}</div>
          </b-list-group-item>
        </b-list-group>
        <div class="text-center">
          <b-button
            variant="success"
            class="mt-2"
            :href="downloadURL"
            target="_blank"
            :disabled="payment.result !== true"
          >
            <FeatherIcon icon="PrinterIcon" />
            Yazdır
          </b-button>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BCard, BListGroup, BListGroupItem, BButton,
} from 'bootstrap-vue'

export default {
  name: 'View',
  components: {
    BRow,
    BCol,
    BCard,
    BListGroup,
    BListGroupItem,
    BButton,
  },
  computed: {
    payment() {
      const data = this.$store.getters['paymentsHistory/getPayment_history']
      if (data) {
        this.getCustomer(data.id_customers)
      }
      return data
    },
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    downloadURL() {
      const urlParams = [
        `&id=${this.payment.id}`,
      ].join('&')
      const url = `${this.$store.state.app.baseURL}/exports/pdf/payments?token=${localStorage.getItem('downloadToken')}${urlParams}`
      return url
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('paymentsHistory/payment_historyView', this.$route.params.id)
    },
    getCustomer(id) {
      this.$store.dispatch('customers/customerView', id)
    },
  },
}
</script>

<style scoped>
</style>
